<template>
  <div class="vehicleManagePage">
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" ref="form" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input
            class="fromInp"
            v-model="manageForm.EnterpriseFullName"
            placeholder="输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="车牌号：" prop="VehicleCarNumber">
          <el-input
            class="fromInp"
            v-model="manageForm.VehicleCarNumber"
            placeholder="输入车牌号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="审核状态" prop="IdentityType" v-if="Status != -1 && Status != 0">
          <el-select v-model="manageForm.Status" placeholder="请选择审核状态">
            <el-option v-for="item in checklist" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="修改时间：" prop="TaskDatetime">
          <el-date-picker
            v-model="manageForm.TaskDatetime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <div>
          <el-form-item label="认证时间：" prop="VerifyDatetime">
            <el-date-picker
              v-model="manageForm.VerifyDatetime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="创建时间：" prop="CreateDatetime">
            <el-date-picker v-model="manageForm.CreateDatetime" type="datetimerange" range-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item> -->
          <el-form-item label="失败原因：" prop="Remark" v-if="Status==-1||Status == 210">
            <el-input
              class="fromInp"
              v-model="manageForm.Remark"
              maxlength="512"
              placeholder="输入失败原因"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="10px">
            <el-button
              type="primary"
              size="medium"
              @click="search()"
              icon="el-icon-search"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="medium"
              @click="resetForm()"
              icon="el-icon-delete"
              >清空</el-button
            >
            <el-button
              icon="el-icon-download"
              type="primary"
              size="medium"
              @click="exportData"
              v-if="activeName != 'second'"
              >导出车辆表格</el-button
            >

            <el-button
              icon="el-icon-s-promotion"
              type="primary"
              size="medium"
              v-show="isNeedVerifyPower"
              @click="
                (automaticForm.enterpriseName = ''),
                  (automaticFormData = []),
                  (flag.automaticDrivers = true)
              "
              v-if="Status == 0"
            >
              按企业自动审核车辆</el-button
            >
            <el-button
              icon="el-icon-s-promotion"
              type="primary"
              size="medium"
              v-show="isNeedVerifyPower"
              @click="putAllEnterpriseCarAuto"
              v-if="Status == 0"
            >
              全部车辆自动审核</el-button
            >
            <el-button
              icon="el-icon-s-promotion"
              type="primary"
              size="medium"
              @click="batchFailShowDialog"
              v-if="Status == 210"
            >
              审核失败</el-button
            >

            <!-- <el-button
              icon="el-icon-s-promotion"
              type="primary"
              size="medium"
              @click="BatchBackDraft"
              v-if="Status == -1"
            >
              退回草稿</el-button
            > -->
          </el-form-item>
        </div>
      </el-form>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="审核中" name="thirdly">
          <!-- 表单 -->
          <div class="facilityBoxItem">
            <el-table
              :data="tableData"
              :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading"
              :height="tableHeight"
              ref="table1"
            >
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="EnterpriseFullName"
                label="企业名称"
                width="260"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleCarNumber"
                label="车牌号"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="CarNumberColor"
                label="车牌颜色"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleType"
                label="车辆类型"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleRTP"
                label="道路运输证号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleLicenseValidDate"
                label="行驶证有效期"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="InNetwork"
                label="北斗入网校验"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.InNetwork"
                    :type="
                      scope.row.InNetwork == '未入网' ? 'danger' : 'success'
                    "
                  >
                    {{ scope.row.InNetwork }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="SubmitDatetime"
                label="修改时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VerifyDatetime"
                label="认证时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- <el-table-column align="center" prop="CreateTime" label="创建时间" width="150" show-overflow-tooltip>
              </el-table-column> -->
              <el-table-column
                align="center"
                prop="Status"
                label="审核状态"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="
                      scope.row.StatusCode == 0
                        ? 'primary'
                        : scope.row.StatusCode == 2
                        ? 'danger'
                        : scope.row.StatusCode == 1
                        ? 'success'
                        : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                    "
                  >
                    {{ scope.row.Status }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="
                      goFacility(
                        scope.row,
                        pagination.page,
                        pagination.pagesize
                      )
                    "
                    icon="el-icon-s-check"
                    >认证
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="`审核失败`" name="first">
          <!-- 表单 -->
          <div class="facilityBoxItem">
            <el-table
              :data="tableData"
              :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading"
              :height="tableHeight"
              ref="table2"
              @selection-change="batchFailSelectionChange"
            >
              <el-table-column
                type="selection"
                align="center"
                width="55"
                fixed="left"
              >
              </el-table-column>
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="EnterpriseFullName"
                label="企业名称"
                width="260"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleCarNumber"
                label="车牌号"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="CarNumberColor"
                label="车牌颜色"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleType"
                label="车辆类型"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleRTP"
                label="道路运输证号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleLicenseValidDate"
                label="行驶证有效期"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="InNetwork"
                label="北斗入网校验"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.InNetwork"
                    :type="
                      scope.row.InNetwork == '未入网' ? 'danger' : 'success'
                    "
                  >
                    {{ scope.row.InNetwork }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="SubmitDatetime"
                label="修改时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VerifyDatetime"
                label="认证时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>

        
            <el-table-column
                align="center"
                prop="Remark"
                label="失败原因"
                width="150" 
                show-overflow-tooltip
              >
            </el-table-column>
          
              <!-- <el-table-column align="center" prop="CreateTime" label="创建时间" width="150" show-overflow-tooltip>
              </el-table-column> -->
              <el-table-column
                align="center"
                prop="Status"
                label="审核状态"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="
                      scope.row.StatusCode == 0
                        ? 'primary'
                        : scope.row.StatusCode == 2
                        ? 'danger'
                        : scope.row.StatusCode == 1
                        ? 'success'
                        : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                    "
                  >
                    {{ scope.row.Status }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="
                      goFacility(
                        scope.row,
                        pagination.page,
                        pagination.pagesize
                      )
                    "
                    icon="el-icon-s-check"
                    >认证
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已认证" name="second">
          <!-- 表单 -->
          <div class="facilityBoxItem">
            <el-table
              :data="tableData"
              :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading"
              :height="tableHeight"
              ref="table3"
            >
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="EnterpriseFullName"
                label="企业名称"
                width="260"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleCarNumber"
                label="车牌号"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="CarNumberColor"
                label="车牌颜色"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleType"
                label="车辆类型"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleRTP"
                label="道路运输证号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleLicenseValidDate"
                label="行驶证有效期"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="InNetwork"
                label="北斗入网校验"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.InNetwork"
                    :type="
                      scope.row.InNetwork == '未入网' ? 'danger' : 'success'
                    "
                  >
                    {{ scope.row.InNetwork }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="Status"
                label="状态"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="
                      scope.row.StatusCode == 0
                        ? 'primary'
                        : scope.row.StatusCode == 2
                        ? 'danger'
                        : scope.row.StatusCode == 1
                        ? 'success'
                        : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                    "
                  >
                    {{ scope.row.Status }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="SubmitDatetime"
                label="修改时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VerifyDatetime"
                label="认证时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="Remark"
                label="备注"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column align="center" label="操作" width="130px">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    icon="el-icon-tickets"
                    size="small"
                    @click="
                      goFacility(
                        scope.row,
                        pagination.page,
                        pagination.pagesize
                      )
                    "
                    >认证信息</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="自动审核中" name="fourth">
          <!-- 表单 -->
          <div class="facilityBoxItem">
            <el-table
              :data="tableData"
              :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading"
              :height="tableHeight"
              ref="table3"
            >
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="EnterpriseFullName"
                label="企业名称"
                width="260"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleCarNumber"
                label="车牌号"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="CarNumberColor"
                label="车牌颜色"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleType"
                label="车辆类型"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleRTP"
                label="道路运输证号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleLicenseValidDate"
                label="行驶证有效期"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="InNetwork"
                label="北斗入网校验"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.InNetwork"
                    :type="
                      scope.row.InNetwork == '未入网' ? 'danger' : 'success'
                    "
                  >
                    {{ scope.row.InNetwork }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="Status"
                label="状态"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="
                      scope.row.StatusCode == 0
                        ? 'primary'
                        : scope.row.StatusCode == 2
                        ? 'danger'
                        : scope.row.StatusCode == 1
                        ? 'success'
                        : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                    "
                  >
                    {{ scope.row.Status }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="SubmitDatetime"
                label="修改时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VerifyDatetime"
                label="认证时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- <el-table-column align="center" prop="CreateTime" label="创建时间" width="150" show-overflow-tooltip>
              </el-table-column> -->
              <el-table-column align="center" label="操作" width="130px">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="
                    goFacility(
                      scope.row,
                      pagination.page,
                      pagination.pagesize
                    )
                  " icon="el-icon-s-check">认证
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="自动审核失败" name="fifth">
          <!-- 表单 -->
          <div class="facilityBoxItem">
            <el-table
              :data="tableData"
              :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading"
              :height="tableHeight"
              @selection-change="batchFailSelectionChange"
              ref="table3"
            >
              <el-table-column
                type="selection"
                align="center"
                width="55"
                fixed="left"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="EnterpriseFullName"
                label="企业名称"
                width="260"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleCarNumber"
                label="车牌号"
                width="100"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="CarNumberColor"
                label="车牌颜色"
                width="100"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VehicleType"
                label="车辆类型"
                show-overflow-tooltip
                width="150"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleRTP"
                label="道路运输证号"
                show-overflow-tooltip
                width="100"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="VehicleLicenseValidDate"
                label="行驶证有效期"
                show-overflow-tooltip
                width="150"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="InNetwork"
                label="北斗入网校验"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.InNetwork"
                    :type="
                      scope.row.InNetwork == '未入网' ? 'danger' : 'success'
                    "
                  >
                    {{ scope.row.InNetwork }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="Status"
                label="状态"
                show-overflow-tooltip
                width="150"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="
                      scope.row.StatusCode == 0
                        ? 'primary'
                        : scope.row.StatusCode == 2
                        ? 'danger'
                        : scope.row.StatusCode == 1
                        ? 'success'
                        : scope.row.StatusCode == 3
                        ? 'warning'
                        : 'info'
                    "
                  >
                    {{ scope.row.Status }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="SubmitDatetime"
                label="修改时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="VerifyDatetime"
                label="认证时间"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- <el-table-column align="center" prop="CreateTime" label="创建时间" width="150" show-overflow-tooltip>
              </el-table-column> -->
              <el-table-column
                align="center"
                fixed="right"
                prop="Remark"
                label="失败原因"
                width="150"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="操作"
                width="130px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="
                      goFacility(
                        scope.row,
                        pagination.page,
                        pagination.pagesize
                      )
                    "
                    icon="el-icon-s-check"
                    >认证
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 分页 -->
      <el-pagination
        background
        style="margin-top: 10px"
        class="pagination"
        @current-change="handleCurrentChange"
        @size-change="sizeChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>

    <!-- 自动审核车辆 -->
    <el-dialog
      title="自动审核车辆"
      :visible.sync="flag.automaticDrivers"
      center
      width="603px"
    >
      <!-- form表单 -->
      <el-form
        class="automaticForm"
        ref="automaticForm"
        :model="automaticForm"
        :inline="true"
      >
        <el-form-item label="企业名称：" prop="enterpriseName">
          <el-input
            class="fromInp"
            v-model="automaticForm.enterpriseName"
            placeholder="输入企业名称查询司机数量"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            @click="getEnterpriseDriverNum"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="putEnterpriseCarAuto()"
            icon="el-icon-upload2"
            :disabled="
              automaticFormData.length == 0 ||
              automaticFormData[0].CarCount == 0
            "
            >提交自动审核</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        v-if="automaticFormData.length"
        :data="automaticFormData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        ref="automaticTable"
        height="96px"
      >
        <el-table-column
          type="index"
          fixed
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          fixed
          prop="EnterpriseName"
          label="企业名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="CarCount"
          label="待审核司机数量"
          width="200px"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 驳回弹窗 -->
    <el-dialog
      class="rejectDialog"
      title="驳回意见"
      :visible.sync="flag.batchFailDialog"
      width="30%"
      center
    >
      <span>驳回 (车辆) 的提交申请：</span>
      <div style="margin: 20px 0 0 0">
        <el-input
          type="textarea"
          maxlength="100"
          show-word-limit
          placeholder="请输入驳回处理意见，限制100字。"
          v-model.trim="rejectValue"
        ></el-input>
        <el-checkbox-group
          v-model="checkFastReplyList"
          style="margin-top: 16px"
        >
          <el-checkbox
            :label="item.Name"
            v-for="item in fastReplyList"
            :key="item.Code"
            style="padding: 5px 0"
          >
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.batchFailDialog = false">取 消</el-button>
        <el-button type="primary" @click="batchFailSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  VehicleList,
  enterpriseDriverNum,
  putEnterpriseCarAuto,
  BatchFailCar,
  GetVerifyPower,
  BatchDraftCar,
} from "@/api/auditInfo/certified/index";
import { getDataDict } from "@/api/common/common";
import { upExport } from "@/api/finance/account/index";
import { _getPhone, _getIsAdmin } from "@/utils/storage";
export default {
  data() {
    return {
      // 当前选中的Tab
      activeName: "thirdly",
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 列表数据
      tableData: [],
      // 控制器
      flag: {
        isPreview: false,
        isAlter: false,
        deleteDriverDialog: false, //删除司机dialog
        automaticDrivers: false, // 自动审核司机
        batchFailDialog: false,
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
        VerifyDatetime: ["", ""],
        CreateDatetime: ["", ""],
        VehicleCarNumber: "",
        Status: "",
        Remark: "",
      },
      UserID: "",
      Status: 0,
      // 剩余&已认证辆车数量
      loading: true,
      label: "未认证",
      checklist: [
        {
          Code: "",
          Name: "全部",
        },
      ],
      tableHeight: null,

      // 自动审核查询条件
      automaticForm: {
        enterpriseName: null,
      },
      automaticFormData: [],
      isPlatForm: _getPhone() == "platform" && _getIsAdmin() == 1,

      //自动审核失败批量处理
      batchFailList: [],
      fastReplyList: [], //快捷回复列表
      checkFastReplyList: [], //选中的快捷回复
      rejectValue: "",

      isNeedVerifyPower: false, //是否有审核权限
    };
  },
  created() {
    // 获取审核状态
    getDataDict({ type: 56 }).then((res) => {
      this.checklist = this.checklist.concat(res.patterSetInfo);
    });
    if (this.$route.query.UserID) {
      this.UserID = this.$route.query.UserID;
    }
    if (this.$route.query.pageIndex) {
      this.pagination.page = Number(this.$route.query.pageIndex);
      this.pagination.pagesize = Number(this.$route.query.pagesize);
      this.manageForm = {
        EnterpriseFullName: this.$route.query.EnterpriseFullName,
        LegalPersonlPhone: this.$route.query.LegalPersonlPhone,
        TaskDatetime: [
          this.$route.query.SubmitDatetimeStart,
          this.$route.query.SubmitDatetimeEnd,
        ],
        VerifyDatetime: [
          this.$route.query.VerifyDatetimeStart,
          this.$route.query.VerifyDatetimeEnd,
        ],
        CreateDatetime: [
          this.$route.query.CreateDatetimeStart,
          this.$route.query.CreateDatetimeEnd,
        ],
        VehicleCarNumber: this.$route.query.CarNumber,
        Status: this.$route.query.auditStatus,
        Remark: this.$route.query.Remark,
      };
    }
    //刷新页面时确定tabs的选中
    this.chooseTabsIndex();
    // 查询车辆列表
    this.GetEnterprise();

    // 获取table表格高度
    this.getTableHeight();

    this.getFastReplyList();

    GetVerifyPower({ code: "CarUsers" }).then((res) => {
      this.isNeedVerifyPower = res.data;
    });
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));

    //挂载window.onresize事件(动态设置table高度)
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  methods: {
    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.GetEnterprise();
    },
    searchChange(type) {
      if (type == "time" && !this.manageForm.TaskDatetime)
        this.manageForm.TaskDatetime = ["", ""];
      if (type == "verifytime" && !this.manageForm.VerifyDatetime)
        this.manageForm.VerifyDatetime = ["", ""];
      if (type == "createtime" && !this.manageForm.CreateDatetime)
        this.manageForm.CreateDatetime = ["", ""];
      this.search();
    },
    // 导出表格
    exportData() {
      this.loading = true;
      let data = {
        fileType: "平台认证车辆导出",
        fileName: "平台认证车辆导出",
        Remark:this.manageForm.Remark,
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime
          ? this.manageForm.TaskDatetime[0]
          : "",
        SubmitDatetimeEnd: this.manageForm.TaskDatetime
          ? this.manageForm.TaskDatetime[1]
          : "",
        VerifyDatetimeStart: this.manageForm.VerifyDatetime
          ? this.manageForm.VerifyDatetime[0]
          : "",
        VerifyDatetimeEnd: this.manageForm.VerifyDatetime
          ? this.manageForm.VerifyDatetime[1]
          : "",
        CreateDatetimeStart: this.manageForm.CreateDatetime
          ? this.manageForm.CreateDatetime[0]
          : "",
        CreateDatetimeEnd: this.manageForm.CreateDatetime
          ? this.manageForm.CreateDatetime[1]
          : "",
        CarNumber: this.manageForm.VehicleCarNumber,
        auditStatus: this.Status == -1 ? 2 : this.manageForm.Status, //审核失败状态只要认证失败的列表
        type: 1,
        Status: Number(this.Status),
      };
      upExport({ Json: JSON.stringify(data) })
        .then((res) => {
          window.location.href = res.pathList[0].pathName;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == "/auditInfo/certifiedCar/index") {
        // 重置路由
        this.$router.push({
          path: "/auditInfo/certifiedCar/index",
        });
      }
    },
    //刷新页面时确定tabs的选中
    chooseTabsIndex() {
      if (this.$route.fullPath.indexOf("first") != -1) {
        this.activeName = "first";
        this.Status = -1;
        this.label = "审核失败";
      } else if (this.$route.fullPath.indexOf("second") != -1) {
        this.activeName = "second";
        this.Status = 1;
        this.label = "已认证";
      } else if (this.$route.fullPath.indexOf("thirdly") != -1) {
        this.activeName = "thirdly";
        this.Status = 0;
        this.label = "审核中";
      } else if (this.$route.fullPath.indexOf("fourth") != -1) {
        this.activeName = "fourth";
        this.Status = 200;
        this.label = "自动审核中";
      } else if (this.$route.fullPath.indexOf("fifth") != -1) {
        this.activeName = "fifth";
        this.Status = 210;
        this.label = "自动审核失败";
      }
    },
    // Tab切换
    handleClick(tab, event) {
      this.loading = true;
      if (tab.label.includes("自动审核失败")) {
        this.Status = 210;
      } else if (tab.label.includes("审核失败")) {
        this.Status = -1;
      } else if (tab.label.includes("已认证")) {
        this.Status = 1;
      } else if (tab.label.includes("自动审核中")) {
        this.Status = 200;
      } else if (tab.label.includes("审核中")) {
        this.Status = 0;
      }
      this.label = tab.label;
      this.resetForm();
      this.getTableHeight();
    },
    // 清空搜索条件
    resetForm() {
      this.loading = true;
      this.pagination.page = 1;
      this.manageForm = {
        Remark:"",
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
        VerifyDatetime: ["", ""],
        CreateDatetime: ["", ""],
        VehicleCarNumber: "",
        Status: "",
      };
      this.pagination.page = 1;
      this.GetEnterprise();
    },
    // 搜索
    search() {
      this.loading = true;
      this.pagination.page = 1;
      this.GetEnterprise();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.GetEnterprise();
      this.$nextTick(() => {
        this.$refs.table1.bodyWrapper.scrollTop = 0;
        this.$refs.table2.bodyWrapper.scrollTop = 0;
        this.$refs.table3.bodyWrapper.scrollTop = 0;
      });
    },
    // 查询司机列表
    GetEnterprise() {
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime
          ? this.manageForm.TaskDatetime[0]
          : "",
        SubmitDatetimeEnd: this.manageForm.TaskDatetime
          ? this.manageForm.TaskDatetime[1]
          : "",
        VerifyDatetimeStart: this.manageForm.VerifyDatetime
          ? this.manageForm.VerifyDatetime[0]
          : "",
        VerifyDatetimeEnd: this.manageForm.VerifyDatetime
          ? this.manageForm.VerifyDatetime[1]
          : "",
        CreateDatetimeStart: this.manageForm.CreateDatetime
          ? this.manageForm.CreateDatetime[0]
          : "",
        CreateDatetimeEnd: this.manageForm.CreateDatetime
          ? this.manageForm.CreateDatetime[1]
          : "",
        CarNumber: this.manageForm.VehicleCarNumber,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        AssignUser: this.UserID,
        type: 1,
        Status: Number(this.Status),
        auditStatus: this.Status == -1 ? 2 : this.manageForm.Status, //审核失败状态只要认证失败的列表
        Remark: this.manageForm.Remark,
      };
      VehicleList({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase.map((item) => {
            item.VehicleLicenseValidDate = item.VehicleLicenseValidDate
              ? item.VehicleLicenseValidDate.slice(0, 11)
              : item.VehicleLicenseValidDate;
            return item;
          });
          localStorage.removeItem("carList");
          if (this.Status == 0 || this.Status == 210) {
            localStorage.setItem("carList", JSON.stringify(this.tableData));
          }
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 详情跳转
    goFacility(item, pageIndex, pagesize) {
      this.$router.push({
        path: "/auditInfo/certifiedCar/carInfo",
        query: {
          VehicleID: item.VehicleID,
          status: this.Status,
          urlType: this.activeName,
          AscriptionUserID: item.AscriptionUserID,
          pageIndex,
          pagesize,
          EnterpriseFullName: this.manageForm.EnterpriseFullName,
          LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
          SubmitDatetimeStart: this.manageForm.TaskDatetime
            ? this.manageForm.TaskDatetime[0]
            : "",
          SubmitDatetimeEnd: this.manageForm.TaskDatetime
            ? this.manageForm.TaskDatetime[1]
            : "",
          VerifyDatetimeStart: this.manageForm.VerifyDatetime
            ? this.manageForm.VerifyDatetime[0]
            : "",
          VerifyDatetimeEnd: this.manageForm.VerifyDatetime
            ? this.manageForm.VerifyDatetime[1]
            : "",
          CreateDateTimeStart: this.manageForm.CreateDatetime[0],
          CreateDateTimeEnd: this.manageForm.CreateDatetime[1],
          CarNumber: this.manageForm.VehicleCarNumber,
          auditStatus: this.manageForm.Status,
          Remark: this.manageForm.Remark,
        },
      });
    },

    //计算table高度(动态设置table高度)
    getTableHeight() {
      let tableH = 250; // 页面其余元素的高度(估值)
      this.$nextTick(() => {
        let formH = this.$refs.form.$el.offsetHeight; // form 查询表单的高度
        this.tableHeight =
          window.innerHeight - tableH - formH <= 150
            ? 150
            : window.innerHeight - tableH - formH;
      });
    },

    // 车辆自动审核 - 查询相关企业
    getEnterpriseDriverNum() {
      if (!this.automaticForm.enterpriseName.trim()) {
        this.$message.info("请输入需要查询的企业名称!");
        return;
      }

      enterpriseDriverNum({
        enterpriseName: this.automaticForm.enterpriseName,
      }).then((res) => {
        let { data = {} } = res || {};
        this.automaticFormData = [data];
        console.log(" this.automaticFormData ", this.automaticFormData);
      });
    },

    // 车辆自动审核 - 提交相关企业司机审核
    putEnterpriseCarAuto(all) {
      let params = {
        isSubmitAll: true,
      };

      // 根据企业提交自动审核
      if (!all) {
        if (this.automaticFormData[0].CarCount == 0) {
          this.$message.info("该企业暂无待审核的车辆!");
          return;
        }

        params = {
          enterpriseUserId: this.automaticFormData[0].UserId,
          isSubmitAll: false,
        };
      }
      putEnterpriseCarAuto(params).then((res) => {
        this.$message.success("提交自动审核成功,请耐心等待!");
        this.flag.automaticDrivers = false;
        this.resetForm();
      });
    },

    // 司机自动审核 - 提交所有待审核司机
    putAllEnterpriseCarAuto() {
      this.$confirm(
        `是否将待审核的 ${this.pagination.total} 位车辆提交自动审核?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.putEnterpriseCarAuto("all");
        })
        .catch(() => {
          return;
        });
    },

    //自动审核失败批量处理
    batchFailSelectionChange(e) {
      this.batchFailList = e;
    },

    batchFailShowDialog() {
      if (this.batchFailList.length <= 0) {
        this.$message.error("请选择司机");
        return;
      }
      this.flag.batchFailDialog = true;
    },

    //自动审核失败批量处理
    batchFailSubmit() {
      let data = {
        Remark: "",
        Details: [],
      };
      data.Remark =
        this.rejectValue +
        (this.rejectValue ? ";" : "") +
        this.checkFastReplyList.join(";");

      this.batchFailList.forEach((item) => {
        let params = {
          CarNumber: item.VehicleCarNumber,
          AscriptionUserID: item.AscriptionUserID,
        };
        data.Details.push(params);
      });
      this.$confirm("确定驳回该车辆的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchFailCar(data).then((res) => {
          this.$message.success("操作成功!");
          this.flag.batchFailDialog = false;
          this.checkFastReplyList = [];
          this.rejectValue = "";
          this.resetForm();
        });
      });
    },

    //获取快捷回复列表
    getFastReplyList() {
      let params = {
        type: 101,
      };
      getDataDict(params).then((res) => {
        this.fastReplyList = res.patterSetInfo;
      });
    },

    //自动审核失败批量处理
    BatchBackDraft() {
      let data = {
        Remark: "",
        Details: [],
      };

      this.batchFailList.forEach((item) => {
        let params = {
          CarNumber: item.VehicleCarNumber,
          AscriptionUserID: item.AscriptionUserID,
        };
        data.Details.push(params);
      });
      this.$confirm("确定退回该车辆的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchDraftCar(data).then((res) => {
          this.$message.success("操作成功!");
          this.resetForm();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.vehicleManagePage {
  .el-dialog {
    top: -10%;
  }

  .vehicleType {
    .el-input.is-disabled .el-input__inner {
      color: #000;
    }
  }
}
</style>

<style lang="scss" scoped>
.btns {
  margin-bottom: 20px;
}

.vehicleForm {
  display: flex;
  flex-wrap: wrap;
}

.addForm {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 450px;
  }

  .el-date-editor {
    width: 100% !important;
  }
}

.pagination {
  margin-top: 10px;
}

.vehicleType {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .uploadArea {
    margin-top: 20px;

    .confirmInfoForm {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .hint {
    display: flex;
    justify-content: center;
  }

  .choose {
    display: flex;
    justify-content: center;
  }
}

.topbtns {
  padding-bottom: 10px;
  margin: 0px 0px 10px;
  border-bottom: 1px solid #eee;
}

.el-form-item {
  margin-bottom: 14px;
}

.head {
  display: flex;

  .btn {
    vertical-align: top;
    margin: 15px 0 0 16px;
  }
}

.v .num {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  right: 100px;
}

.facilityBoxItem {
  background-color: #fff;
  padding: 0px;
  box-sizing: border-box;
}

.automaticForm {
  margin-top: 15px;
}
</style>
